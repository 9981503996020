<template>
  <div class="container">
    <div class="mx-auto loginContent">
      <div class="bread-crumb my-3">
        <a
          class="text-primary"
          href="javascript:void(0);"
          @click="$router.push('/').catch(() => {})"
          >HOME</a
        >
        > ACTIVATE ACCOUNT
      </div>
      <div class="card text-center mb-5 pb-5">
        <div class="card-body p-5">
          <h2 class="text-primary">Activate Your Account</h2>
          <br />
          <h3>
            Your account is ready to be activated. Follow the link in your email
            to activate your account. The activation link expires shortly.
          </h3>
          <br />
          <h4>Click Resend Link to send a new activation link.</h4>
          <button type="button" @click="onResendCode" class="btn btn-primary">
            RESEND LINK
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProfileService from "@/services/ProfileService.js";

export default {
  name: "AccountActivation",
  title: "Account Activation",
  components: {},
  methods: {
    async onResendCode() {
      try {
        this.$store.commit("auth/setLoading", true);
        const tenantId = this.$store.getters["tenant/tenantId"];
        const profileService = new ProfileService(tenantId);
        await profileService.resendRegistrationCode(this.$route.params.userId);
        this.$store.commit("alert/setErrorAlert", {
          type: "alert-info",
          message:
            "Account confirmation link resent. Previous link will not be valid...",
          layer: "public"
        });
      } catch (err) {
        this.$store.commit("alert/setErrorAlert", {
          type: "alert-danger",
          message: "Something went wrong...",
          layer: "public"
        });
      } finally {
        this.$store.commit("auth/setLoading", false);
      }
    },
    notify() {
      this.$store.commit("alert/setErrorAlert", {
        type: "alert-info",
        message:
          "Your account has been created, and a notification link was sent to your email address.",
        layer: "public"
      });
    }
  },
  created() {
    this.notify();
  }
};
</script>

<style scoped>
.card-body {
  margin-bottom: 160px;
}
h3 {
  font-size: large;
}
</style>
